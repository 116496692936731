
import { FlashMessageMixin, PageIdMixin, ParamCustomerTypeSlugMixin } from '~/mixins'

export default {
  name: 'CustomerTypeSlugIndex',
  mixins: [FlashMessageMixin, PageIdMixin, ParamCustomerTypeSlugMixin],
  layout: context => context.store.getters['store__app/getCurrentLayoutForDevice'],
  created() {
    if (!this.pageId) {
      this.$nuxt.error({ statusCode: 404 })
    }
  }
}
